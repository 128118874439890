import { Button, Col, Row, Typography, Skeleton, Modal, Input } from "antd";
import { useState, useEffect } from "react";
import { toGetNotificationsList } from "./Api";
import cookie from "react-cookies";

import "../../Styles/TwoTables.scss";
import { toResultAuthorisationApi } from "../Vente/APIs";
import { errorMsg } from "../../Utils/Utils";

const Notifications = (props) => {
  const [loading, setLoading] = useState(true);
  const [visible, setIsVisible] = useState(false);
  const [selected, setSelected] = useState(null);
  const [accept, setAccept] = useState(0);
  const [Notificationss, setNotificationss] = useState([]);
  const [password, setPassword] = useState("");

  useEffect(() => {
    toGetDatas();
  }, []);
  const toGetDatas = () => {
    toGetNotificationsList().then((res) => {
      setNotificationss(res);
      setLoading(false);
    });
  };
  const resultDemand = (clientId, userId, accept, password) => {
    toResultAuthorisationApi(clientId, userId, accept, password).then((res) => {
      if (res) {
        toGetDatas();
        setIsVisible(false);
      } else {
        errorMsg("Merci de vérifier le code");
      }
    });
  };

  return (
    <>
      <Modal
        title='Code'
        onCancel={() => setIsVisible(false)}
        onOk={() => resultDemand(selected.clientId, selected.userId, accept, password)}
        visible={visible}
      >
        <Input.Password onChange={(e) => setPassword(e.target.value)} />
      </Modal>
      <>
        <Row>
          <Col span='12'>
            <Typography.Title level='2'>Notifications</Typography.Title>
          </Col>
        </Row>

        <Row
          style={{
            overflowY: "hidden",
          }}
        >
          <Col span='24'>
            <div className='table-wrapper auto--overflow'>
              <table className='ant-table' style={{ width: "100%" }}>
                <thead className='ant-table-thead'>
                  <tr>
                    <th className='ant-table-cell'>Date création</th>
                    <th className='ant-table-cell'>Vendeur</th>
                    <th className='ant-table-cell'>Client</th>
                    <th className='ant-table-cell'></th>
                  </tr>
                </thead>
                <Skeleton active loading={loading} />

                <tbody className='ant-table-tbody'>
                  {Notificationss.length > 0 &&
                    Notificationss.map((item, i) => (
                      <tr key={i} style={{ backgroundColor: item.status === 1 ? "#58c5cc" : item.status === -1 ? "#ef5350" : "" }}>
                        <td className='ant-table-cell'>{item.date}</td>
                        <td className='ant-table-cell'>{item.vendeur}</td>
                        <td className='ant-table-cell'>{item.client}</td>
                        <td>
                          {item.status === 0 && cookie.load("notification") == "true" && (
                            <>
                              <Button
                                style={{ marginTop: "15px", textAlign: "center" }}
                                type='primary'
                                size='small'
                                onClick={() => {
                                  setSelected(item);
                                  setIsVisible(true);
                                  setAccept(1);
                                }}
                              >
                                Valider
                              </Button>
                              <Button
                                style={{ marginLeft: "15px", textAlign: "center" }}
                                type='danger'
                                size='small'
                                onClick={() => {
                                  setSelected(item);
                                  setIsVisible(true);
                                  setAccept(-1);
                                }}
                              >
                                Refuser
                              </Button>
                            </>
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
      </>
    </>
  );
};

export default Notifications;
